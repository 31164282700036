import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  Content,
  PaginatedGrid,
  componentIterator,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"about-page"}>
          <Image
            src={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              componentIdentifier: "background",
              numToSelect: 1,
            })}
            className={"page-background-image"}
            background={true}
          >
            <div />
          </Image>
          <Grid
            className={"about-content-container"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column width={6}>
              <Content className={"about-content"} textAlign={"left"}>
                <Content.Image
                  background={true}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "HeroElement",
                    numToSelect: 1,
                  })}
                />
                <Content.Markup
                  width={16}
                  isMarkdown={false}
                  content={
                    <div>
                      <p>Hi!</p>
                      <p>
                        We are Kristen & Cory Driscoll, and we are happy to
                        welcome you to the Wired Bean! We purchased the company
                        in March of 2023 and are excited for this adventure.
                      </p>
                      <p>
                        Kristen has managed the company since the East Grand
                        Forks store opened in 2021. She fell in love with the
                        company culture, people, and the coffee of course!
                        Kristen knew she wanted to be a part of something
                        bigger, which is why they took this leap of faith. Cory
                        is the trumpet professor at the University of North
                        Dakota and doesn't quite understand the deliciousness
                        that is coffee yet, but we are working on that! Although
                        he is not a coffee lover, he loves this community he
                        grew up in and is excited to share his positivity and
                        make sure everyone has a great experience with us.
                      </p>
                      <p>
                        We have two daughters, Matilda & Charlotte and a very
                        large cat named Tiki. We enjoy road trips, gardening,
                        and spending time with our family.
                      </p>
                    </div>
                  }
                />
              </Content>
            </Grid.Column>
            <Grid.Column
              className={"about-image-gallery-container"}
              width={10}
              textAlign={"left"}
            >
              <PaginatedGrid
                rows={3}
                className={"image-gallery"}
                mobile={{ columns: 2, rows: 4, stackable: false }}
                items={componentIterator({
                  iterator: getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "ImageCollection",
                  }),
                  component: (
                    <Image
                      background={true}
                      style={{ paddingTop: "100%", height: "100%" }}
                    />
                  ),
                  propMap: { src: "__all__" },
                })}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
